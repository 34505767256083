import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/travels/Layout";
import Tickets from "./components/travels/Tickets";
import Loader from "./components/common/Loader";
import Booking from "./components/travels/Booking";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Tickets />}></Route>
                    <Route path="/booking" element={<Booking />}></Route>
                </Route>
            </Routes>
            {/* <Loader /> */}
        </div>
    );
}

export default App;
