import { createContext, useContext, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

const DataContext = createContext({});

export const CommonProvider = (props) => {
    const [error, setError] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [dictionary, setDictionary] = useLocalStorage("dictionary", {})
    const [language, setLanguage] = useLocalStorage("language", "en")
    const [selectedFlight, setSelectedFlight] = useState({})
    // const [otpToken, setOtpToken] = useLocalStorage("otpToken", "")


    const translate = (word, lang = language || "en", is_number = false) => {
        if (is_number) {
            return [...String(word)].map(letter => dictionary?.[letter]?.[lang] || letter).join("")
        }
        const tWord = dictionary?.[word]?.[lang] || word
        return tWord
    }

    const showProgress = (status) => {
        setShowLoader(status)
    }

    const value = { error, setError, showProgress, dictionary, setDictionary, translate, language, setLanguage, selectedFlight, setSelectedFlight };

    return (
        <DataContext.Provider value={value}>
            {props.children}
        </DataContext.Provider>
    );
};


export const useCommon = () => {
    return useContext(DataContext);
};