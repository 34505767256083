import React, { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './Override.css';
import moment from 'moment';


const MySingleDatePicker = ({ setTravelDate, departureDate }) => {
    const [date, setDate] = useState(null);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        setTravelDate({ departureDate: moment(date).format("YYYY-MM-DD") })
    }, [date])


    return (
        <div className='position-relative'>

            <SingleDatePicker
                date={!departureDate?.startsWith("Invalid") && departureDate && moment(departureDate)} // The selected date
                onDateChange={(date) => setDate(date)} // Handler for changing date
                focused={focused} // Whether the picker is focused
                placeholder="Leaving on"
                onFocusChange={({ focused }) => setFocused(focused)} // Handler for focus change
                id="single-date-picker" // Unique ID for the component
                numberOfMonths={1} // Shows only one month
                isOutsideRange={() => false} // Allows all dates to be selectable
                displayFormat="ddd, DD MMM" // Format of the displayed date
                showClearDate={false} // Adds a button to clear the selected date
            />
            {(date || (!departureDate?.startsWith("Invalid") && departureDate)) && <span className='d-inline-block text-start position-absolute start-0 top-0 font-size-9 z-3 mt-2 ms-3 text-secondary' >Leaving on</span>}

        </div>
    );
};

export default MySingleDatePicker;
