import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './Override.css';
import moment from 'moment';

const MyDateRangePicker = ({ setTravelDate, departureDate, returnDate }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        setTravelDate({ departureDate: moment(startDate).format("YYYY-MM-DD"), returnDate: moment(endDate).format("YYYY-MM-DD") })
    }, [startDate, endDate])

    // useEffect(() => {
    //     console.log(departureDate)
    //     // setStartDate(moment(departureDate).format("YYYY-MM-DD"))
    //     // setEndDate(moment(returnDate).format("YYYY-MM-DD"))
    // }, [departureDate, returnDate])

    useEffect(() => {
        console.log(endDate, returnDate)

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='position-relative'>
            <DateRangePicker
                startDate={!departureDate?.startsWith("Invalid") && departureDate && moment(departureDate)} // Start date of the range
                startDatePlaceholderText={"Leaving on"}
                endDatePlaceholderText={"Returning on"}
                startDateId="start-date" // Unique ID for the start date
                endDate={!returnDate?.startsWith("Invalid") && returnDate && moment(returnDate)} // End date of the range
                endDateId="end-date" // Unique ID for the end date
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                }} // Callback for date change
                focusedInput={focusedInput} // Which input is focused
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // Callback for focus change
                displayFormat="ddd, DD MMM" // Format displayed in the input
                isOutsideRange={() => false} // Allow all dates (adjust as needed)
                minimumNights={0} // Minimum nights for date range (set to 0 to allow same-day range)
                showClearDates={false} // Option to clear dates
                numberOfMonths={isMobile ? 2 : 2} // Number of months shown in the calendar
                reopenPickerOnClearDates={true}
                orientation={isMobile ? 'vertical' : 'horizontal'}
            />
            {(startDate || (!departureDate?.startsWith("Invalid") && departureDate)) && <span className='d-inline-block text-start text-secondary position-absolute start-0 top-0 font-size-9 z-3 mt-2 ms-3'>Leaving on</span>}
            {(endDate || (!returnDate?.startsWith("Invalid") && returnDate)) && <span className='d-inline-block text-start text-secondary position-absolute start-50 top-0 font-size-9 z-3 mt-2' style={{ marginLeft: "1.2em" }}>Returning on</span>}
        </div>
    );
};

export default MyDateRangePicker;
