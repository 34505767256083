import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = () => {
    return (
        <div className="" style={{ maxWidth: 1440, margin: "0 auto" }}>
            <div className="row m-0 mx-2">
                <div className="col-sm-12 col-lg-10 mx-auto">
                    <Header />
                </div>
            </div>
            <div className="row m-0 mx-2">
                <div className="col-sm-12 col-lg-10 mx-auto">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
export default Layout;