import { Tab } from "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import { IoAirplane, IoBus } from "react-icons/io5";
import AirSearchingPanel from "./AirSearchingPanel";
import { BsTrainFront } from "react-icons/bs";
import FlightInfo from "./FlightInfo";
import { useSearchParams } from "react-router-dom";
import { decodeSearchParams } from "../../utils/Util";

const TicketFindingPanel = () => {
    const [key, setKey] = useState('air');
    const [searchParams, setSearchParams] = useSearchParams();


    const handleTab = (t) => {
        setSearchParams({ ticket: t })
    }

    useEffect(() => {
        const params = decodeSearchParams(searchParams)
        setKey(params?.ticket)
    }, [searchParams])

    return (

        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => handleTab(k)}
            className="mb-3"
        >
            <Tab eventKey="air" className="py-3" title={<span className="fs-6  d-flex align-items-center"><IoAirplane className="fs-4 me-2" /> Air</span>}>
                <AirSearchingPanel />
                <div className="mt-5">
                    Under Development
                </div>
            </Tab>
            <Tab eventKey="bus" title={<span className="fs-6  d-flex align-items-center"><IoBus className="fs-4 me-2" /> Bus</span>}>
                Under Development
            </Tab>
            <Tab eventKey="train" title={<span className="fs-6 d-flex align-items-center"><BsTrainFront className="fs-4 me-2" /> Train</span>}>
                Under Development
            </Tab>
        </Tabs>
    );
};
export default TicketFindingPanel;
