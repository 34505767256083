import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { decodeSearchParams } from "../../utils/Util";
import FlightInfo from "./FlightInfo";

const Booking = ({ data }) => {
    const [searchParams] = useSearchParams();
    const [service, setService] = useState();

    useEffect(() => {
        const params = decodeSearchParams(searchParams)
        setService(params?.ticket)
    }, [searchParams])

    return <div className="my-4">
        <div className="d-flex flex-warp justify-content-between align-items-center">
            <h4 className="text-orange m-0">Review Your Booking</h4>
            <nav aria-label="breadcrumb mt-2 mt-md-0">
                <ol class="breadcrumb m-0">
                    <li className="breadcrumb-item"><a href="/" className="text-decoration-none text-dark">Ticket Selection</a></li>
                    <li className="breadcrumb-item"><span className="text-orange">Booking</span></li>
                    <li className="breadcrumb-item active" aria-current="page">Payment</li>
                </ol>
            </nav>
        </div>
        <div className="row m-0 mt-4 gx-4">
            <div className="col-sm-12 col-lg-8 ps-0">
                <div className="">
                    {/* <FlightInfo /> */}
                </div>
            </div>
            <div className="col-sm-12 col-lg-4 pe-0">
                <div className="bg-light">
                    price
                </div>
            </div>
        </div>
    </div>
}
export default Booking