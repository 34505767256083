import { createContext, useContext } from "react";
import axios from "axios";
import { useCommon } from "./useCommon";
// import { useAuth } from "./useAuth";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {

    // const auth = useAuth();
    const cm = useCommon();

    console.log(process.env.REACT_APP_API_SERVER)

    const abApi = axios.create({
        baseURL: process.env.REACT_APP_API_SERVER,
        headers: {
            "Content-Type": "application/json",
        },
        timeout: 1000 * 60 * 2,
    });


    abApi.interceptors.request.use(
        async (config) => {
            // if (auth.authToken) {
            //     config.headers = Object.assign({
            //         Authorization: auth.authToken
            //     }, config.headers)
            // }
            return config
        }
    );


    abApi.interceptors.response.use(
        async (response) => {
            cm?.setError("")
            return response;
        },
        (error) => {
            if (error?.response?.data?.msg === "Token Expired!") {
                // auth.logout()
                window.location = "/"
            }
            else if (error?.code === "ERR_NETWORK") {
                cm?.setError("Server connection is not available!")
            } else if (error?.code === "ERR_BAD_RESPONSE") {
                cm?.setError(error?.response?.data || error?.response?.statusText || error?.msg || "Server errror! Try again.")
            }
            else {
                throw error;
            }

        }
    );

    const loginAuth = async (email_or_phone, password) => {
        try {

            const cred = { email_or_phone: email_or_phone, password: password }
            const response = await abApi.post("/login/", cred);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const renewAuth = async (email_or_phone) => {
        try {
            const data = { email_or_phone: email_or_phone }
            const response = await abApi.post("/renew-auth/", data);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const register = async (cred) => {
        try {

            const response = await abApi.post("/register/", cred);
            return response?.data;
        } catch (err) {
            throw err
        }
    };

    const quickRegister = async (cred) => {
        try {
            const response = await abApi.post("/quick-register/", cred);
            return response?.data;
        } catch (err) {
            throw err
        }
    };

    const verifyCode = async (cred) => {
        try {

            const response = await abApi.post("/register/verify-code/", cred);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };

    const verifyRegOtp = async (cred) => {
        try {

            const response = await abApi.post("/register/verify-reg-otp/", cred);
            return response?.data;
        } catch (err) {
            throw err;
        }
    };


    const getDictionary = async () => {
        try {
            const response = await abApi.get("/dictionary/");
            return response?.data?.results ? response?.data?.results : response?.data;
        } catch (err) {
            throw err
        }
    };

    const getFaqs = async (params) => {
        try {
            const response = await abApi.get("/faqs/", { params });
            return response?.data;
        } catch (err) {
            throw err
        }
    };

    const getAirports = async () => {
        try {
            const response = await abApi.get("/air/airports/");
            return response?.data?.results || response?.data;
        } catch (err) {
            throw err
        }
    };


    // const createOrder = async (aliasId, data) => {
    //     try {
    //         const response = await abApi.post(`/ads/${aliasId}/create-order/`, data);
    //         return response?.data;
    //     } catch (err) {
    //         throw err;
    //     }
    // };

    // const getOrders = async (params) => {
    //     try {
    //         const response = await abApi.get(`/orders/`, { params });
    //         return response?.data;
    //     } catch (err) {
    //         throw err;
    //     }
    // };


    // const updateOrder = async (order_no, data) => {
    //     try {
    //         const response = await abApi.put(`/orders/${order_no}/`, data);
    //         return response?.data;
    //     } catch (err) {
    //         throw err;
    //     }
    // };

    // const updatePostLike = async (aliasId) => {
    //     try {
    //         const response = await abApi.post(`/ads/${aliasId}/like/`);
    //         return response?.data;
    //     } catch (err) {
    //         throw err;
    //     }
    // };


    const value = {
        loginAuth,
        renewAuth,
        register,
        quickRegister,
        getDictionary,
        getFaqs,
        getAirports,
        verifyCode,
        verifyRegOtp,

    };
    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;

}

export const useApi = () => {
    return useContext(ApiContext);
};