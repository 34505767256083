import { useEffect, useRef, useState } from "react"
// import useOutsideClick from "../hooks/useOutsideClick"
import { useCommon } from "../hooks/useCommon"
import { useApi } from "../hooks/useApi"
import useAlert from "../hooks/useAlert"
import moment from "moment"

const Destinations = ({ placeholder, classStyle, where, travelFromTo, setTravelFromTo }) => {
    // const [showList, setShowList] = useState(false)
    const [airportsData, setAiportsData] = useState([])
    const [airports, setAiports] = useState([])
    const [selectedAirport, setSelectedAirport] = useState({})
    const [clientTimezone, setClientTimezone] = useState(null)
    const inputRef = useRef(null);


    const cm = useCommon();
    const api = useApi();
    const alert = useAlert();

    // useOutsideClick(inputRef, () => {
    //     if (showList) setShowList(false);
    // });

    const callGetAirportsApi = async () => {
        try {
            cm?.showProgress(true)
            const res = await api?.getAirports()
            if (res) {
                setAiportsData(res)
                setAiports(res)
                cm?.showProgress(false)
            }
        } catch (err) {
            cm?.showProgress(false)
            alert.show(err?.response?.data?.msg || err, "error")
        }
    };

    const handleAirportList = (e) => {
        const fVal = e?.target?.value?.trim().toLowerCase()
        const fAirports = airportsData?.filter(a => a?.code?.toLowerCase().includes(fVal) || a?.city?.toLowerCase().includes(fVal) || a?.state?.toLowerCase().includes(fVal) || a?.airport?.toLowerCase().includes(fVal) || a?.country?.toLowerCase().includes(fVal))
        setAiports(fAirports)
    }

    const handleAirportSelect = (airport) => {
        setSelectedAirport(airport)
        setTravelFromTo({ ...airport })
    }


    useEffect(() => {
        callGetAirportsApi()
        // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const tzCity = tz.split("/")[1] || ""
        // tz.city && setClientTimezone(tzCity)

    }, [])


    useEffect(() => {
        if (travelFromTo?.from && travelFromTo?.to && travelFromTo?.from?.code === travelFromTo?.to?.code) {
            alert.show("Same destination could not set!", "error")
            setTravelFromTo("")
            setSelectedAirport("")
        } else {
            setSelectedAirport(travelFromTo[where])
        }
    }, [travelFromTo])


    const renderAirporList = () => {
        return airports?.length > 0 ? airports?.map((a, ix) => {
            return <li key={`airport_${a?.code}`}>
                <button class="dropdown-item dropdown-item-secondary rounded d-flex justify-content-between py-2 px-2" onClick={() => handleAirportSelect(a)} >
                    <div className="flex-fill">
                        <p className="mb-0 text-start text-break word-break text-wrap font-size-10 fw-semibold">{a?.city || a?.state}, {a?.country}</p>
                        <p className="mb-0 font-size-9 text-break word-break text-wrap text-start">{a?.name}</p>
                    </div>
                    <span className="d-inline-block font-size-10 text-end ms-2">{a?.code}</span>
                </button></li >

        }
        ) : <button className="btn btn-light w-100 text-start" disabled>Not Found</button>
    }

    return <div class="dropdown">
        <button class="btn btn-outline-dark bg-white text-dark border border-dark w-100 text-start" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => inputRef.current.focus()} style={{ minHeight: 60 }}>
            <span className={"d-block ps-2 text-secondary " + (selectedAirport?.code && "font-size-9")}>{placeholder}</span>
            <span className="d-block ps-2 text-orange">{selectedAirport?.city}</span>
        </button>
        <ul class="dropdown-menu p-1 w-100">
            <input ref={inputRef} type="text" autoFocus className="form-control font-size-10 mb-2" placeholder="Type the location or airport name" onChange={handleAirportList}></input>
            <div className="overflow-scroll" style={{ maxHeight: 400 }}>
                {renderAirporList()}
            </div>
        </ul>
    </div>


}

export default Destinations