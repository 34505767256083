import { IoPersonOutline, IoSearch } from "react-icons/io5";
import { LuArrowLeftRight, LuArrowRightLeft } from "react-icons/lu";
import { useEffect, useState } from "react";
import Destinations from "./Destinations";
import MyDateRangePicker from "../common/MyDateRangePicker";
import useAlert from "../hooks/useAlert";
import MySingleDatePicker from "../common/MySingleDatePicker";

const AirSearchingPanel = () => {
    const [trip, setTrip] = useState({ id: "one", name: "One Way" })
    const [tripClass, setTripClass] = useState({ id: "economy", name: "Economy" })
    const [travelFromTo, setTravelFromTo] = useState({
        from: "",
        to: "",
        order: 0
    })
    const [travelDate, setTravelDate] = useState({
        departureDate: "",
        returnDate: ""
    })
    const [traveler, setTraveler] = useState({
        adult: 1,
        children: 0,
        infant: 0,
        total: 1
    })

    const alert = useAlert()


    const handleTravelFromTo = () => {
        const from = travelFromTo?.from || "";
        const to = travelFromTo?.to || "";
        const changedTravelDirection = { from: to, to: from, order: travelFromTo?.order === 0 ? 1 : 0 }
        setTravelFromTo(changedTravelDirection)
    }


    useEffect(() => {
        setTraveler({ ...traveler, total: parseInt(traveler?.adult) + parseInt(traveler?.children) + parseInt(traveler?.infant) })
    }, [traveler?.adult, traveler?.children, traveler?.infant])

    useEffect(() => {
        if (trip?.id === "one") {
            setTravelDate({ departureDate: travelDate?.departureDate })
        } else {
            setTravelDate({ departureDate: travelDate?.departureDate, returnDate: travelDate?.returnDate })
        }
    }, [trip])

    const handleUpdateTravelFrom = (v) => {
        setTravelFromTo({ ...travelFromTo, from: v })
    }

    const handleUpdateTravelTo = (v) => {
        setTravelFromTo({ ...travelFromTo, to: v })
    }

    const renderTrip = () => {
        const trips = [{ id: "one", name: "One Way" }, { id: "round", name: "Round Trip" }]
        return trips.map(t => <button key={"trip_" + t?.id} className={(trip?.id === t?.id ? "btn-dark" : "btn-outline-dark") + " btn btn-sm border-0 me-1"} onClick={() => setTrip(t)}>{t?.name}</button >)
    }

    const renderTripClass = () => {
        const classes = [{ id: "economy", name: "Economy" }, { id: "business", name: "Business" }]
        return classes.map(c => <button key={"class_" + c?.id} className={(tripClass?.id === c?.id ? "btn-dark" : "btn-outline-dark") + " btn btn-sm border-0 me-2"} onClick={() => setTripClass(c)}>{c?.name}</button >)
    }

    const handleSearch = () => {
        if (!(travelFromTo?.from?.code && travelFromTo?.to?.code && travelDate?.departureDate)) {
            alert.show("Travel destination and date are required", "info")
        }
    }
    return (
        <div>
            <div className="bg-secondary bg-opacity-10 pt-3 px-2 pb-5 border border-orange border-opacity-10 rounded position-relative shadow-sm">
                <div className="row m-0">
                    <div className="col-sm-12 d-flex">
                        <div className="d-flex">
                            {renderTrip()}
                        </div>
                    </div>
                </div>
                <div className="row gx-2 m-0 mx-2 mt-2">
                    <div className="col-sm-12 col-lg-6 d-flex justify-content-between position-relative">
                        <div className="row gx-1 w-100 m-0">
                            <div className="col-6 ps-0">
                                <Destinations placeholder="Leaving from" where="from" classStyle="me-1" travelFromTo={travelFromTo} setTravelFromTo={handleUpdateTravelFrom} />
                            </div>
                            <div className="col-6 pe-0">
                                <Destinations placeholder="Going to" where="to" classStyle="ms-1" travelFromTo={travelFromTo} setTravelFromTo={handleUpdateTravelTo} />
                            </div>
                        </div>
                        <button className="btn btn-sm border-secondary bg-white shadow-sm rounded-circle position-absolute top-50 start-50 translate-middle d-flex align-items-center py-2" onClick={handleTravelFromTo}>{travelFromTo?.order === 0 ? <LuArrowLeftRight className="fs-6" /> : <LuArrowRightLeft className="fs-6" />}</button>
                    </div>
                    <div className={"col-sm-6 mt-2 mt-lg-0 " + (trip?.id === "one" ? "col-lg-3" : "col-lg-4")}>
                        {
                            trip?.id === "one" ? <MySingleDatePicker setTravelDate={setTravelDate} departureDate={travelDate?.departureDate} /> : <MyDateRangePicker setTravelDate={setTravelDate} departureDate={travelDate?.departureDate} returnDate={travelDate?.returnDate} />
                        }
                    </div>
                    <div className={"col-sm-6 d-flex mt-2 mt-lg-0 align-self-start " + (trip?.id === "one" ? "col-lg-3" : "col-lg-2")}>
                        <div className="btn-group w-100" role="group">
                            <button type="button" className="btn btn-outline-dark bg-white text-dark dropdown-toggle rounded d-flex align-items-center py-3" data-bs-toggle="dropdown" aria-expanded="false" style={{ height: 60 }}>
                                <IoPersonOutline className="fs-4 d-inline-block d-md-none" /><span className="mx-2 text-start text-orange w-100 text-wrap lh-1">{traveler?.total} {traveler?.total > 1 ? "Travelers" : "Traveler"} <span className="font-size-9 text-dark">({tripClass?.name})</span></span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-start dropdown-menu-lg-end" style={{ minWidth: 250 }}>
                                <div className="dropdown-item dropdown-item-secondary d-flex justify-content-between align-items-center py-2" >
                                    <span className="d-inline-block me-2">Adult</span>
                                    <select className="form-select ms-2" style={{ width: "35%" }} onChange={e => setTraveler({ ...traveler, adult: e.target.value })}>
                                        {Array(10).fill(0).map((_, i) => i + 1).map(i => <option key={"adult_" + i} value={i}>{i}</option>)}
                                    </select>
                                </div>
                                <div className="dropdown-item dropdown-item-secondary d-flex justify-content-between align-items-center py-2">
                                    <div>
                                        <span className="d-block me-2">Children</span>
                                        <span className="d-block me-2 font-size-9">Aged 2 - 11</span>
                                    </div>
                                    <select className="form-select ms-2" style={{ width: "35%" }} onChange={e => setTraveler({ ...traveler, children: e.target.value })}>
                                        {Array(10).fill(0).map((_, i) => i).map(i => <option key={"children_" + i} value={i}>{i}</option>)}
                                    </select>
                                </div>
                                <div className="dropdown-item dropdown-item-secondary d-flex justify-content-between align-items-center py-2">
                                    <div>
                                        <span className="d-block me-2">Infant</span>
                                    </div>
                                    <select className="form-select ms-2" style={{ width: "35%" }} onChange={e => setTraveler({ ...traveler, infant: e.target.value })}>
                                        {Array(10).fill(0).map((_, i) => i).map(i => <option key={"infant_" + i} value={i}>{i}</option>)}
                                    </select>
                                </div>
                                <div className="mt-2 border-top px-3 py-1">
                                    <span className="d-block font-size-10">Class</span>
                                    <div className="d-flex flex-wrap mt-2">
                                        {renderTripClass()}
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="position-absolute top-100 start-50 translate-middle">
                        <button className="btn btn-orange rounded-pill py-2 px-5 shadow-sm" onClick={handleSearch}><IoSearch className="fs-4 me-2" />Search</button>
                    </div>
                </div>
            </div>

        </div >
    );
};
export default AirSearchingPanel;
